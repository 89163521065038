<template>
  <b-row
    class="m-0"
    :class="{'fill-row': !copyPasteEnabled}"
  >
    <b-col
      class="problem-card-title text-center"
      md="12"
    >
      <h4 class="mb-0">
        {{ getProblemHintsDescription($t("student-test-module.fill-problem-hint")) }}
      </h4>
    </b-col>

    <b-col
      md="12"
      class="problem-overview-content"
    >
      <div v-if="rules?.clues"
           class="text-center"
      >
        {{ rules.clues }}
      </div>
      <div
        v-if="defaultAnswers.length >= 1 && shouldShowHints.hints"
        class="text-center"
      >
        <div
          v-if="feedbackQuestionComputed.length > 0"
          class="d-flex justify-content-center"
        >
          <div
            v-for="ans,index in feedbackQuestionComputed"
            :key="index"
            no-body
            class="mr-2"
          >
            <img
              v-if="shouldShowHints.image_hints"
              v-image-preview
              :src="ans.image_url || ans.image"
              thumbnail
              fluid
              style="height: 150px; width: 150px;"
            >
            <div
              v-if="shouldShowHints.text_hints"
              class="small text-bold"
              v-html="ans.title"
            />
          </div>
        </div>
        <template v-else-if="shouldShowHints.text_hints">
          <span
            v-for="ans,index in defaultAnswers"
            :key="index"
            class="mr-2"
            v-html="ans"
          />
        </template>
      </div>
      <div v-if="bodyAudio">
        <audio-player :src="bodyAudio" />
      </div>
      <validation-observer ref="submitQuestionForm">
        <div class="ck-content">
          <!-- eslint-disable vue/no-v-html -->
          <span
            v-if="fullHtml"
            class="custom-template"
            v-html="fullHtml"
          />
          <!-- eslint-enable vue/no-v-html -->
          <!-- <span v-html="fullHtml" /> -->
          <div
            v-else
            class="ck-content"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="template && template.before_template && template.before_template.length>0
                &&
                ![
                  'Shapes',
                  'SideCard',
                ].includes(getTemplateComponent)
                ||
                (getTemplateComponent === 'Shapes' && template.template[0].type !== 'fib')
              "
              v-html="beforeHtml"
            />
            <!-- eslint-enable vue/no-v-html -->
            <component
              :is="getTemplateComponent"
              v-if="template && template.template"
              :place-holder="placeHolder"
              :data="template.template"
              :is-a-problem="true"
              :template-options="getTemplateOptions"
            >
              <div
                v-if="
                  [
                    'Shapes',
                    'SideCard',
                  ].includes(getTemplateComponent)
                    ||
                    (getTemplateComponent === 'Shapes' && template.template[0].type !== 'fib')"
                v-html="beforeHtml"
              />
            </component>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="
                template &&
                  template.after_template &&
                  template.after_template.length > 0
              "
              v-text-to-speech
              v-html="afterHtml"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
          <span
            v-for="(question, index) in localQuestions"
            v-show="false"
            :key="'question_' + index"
          >
            <validation-provider
              v-if="question == placeHolder"
              v-slot="{ errors }"
              :name="'Field_' + (index + 1)"
              rules="required"
            >
              {{ answers[index] }}
              <b-form-input
                :id="'Field_' + (index + 1)"
                v-model="answers[index]"
                :disabled="mode === 'feedback'"
                style="display: inline-block; width: 150px; margin-top: 2px"
                class="mx-1 right-answer"
                :state="errors.length > 0 ? false : null"
                size="sm"
                :name="'Field_' + (index + 1)"
                onpaste="return ${copyPasteEnabled}"
                @input="checkValidation"
              />
            </validation-provider>
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-else
              v-html="question"
            />
            <!-- eslint-enable vue/no-v-html -->
          </span>
        </div>
      </validation-observer>
    </b-col>
    <portal
      to="problemFeedBack"
    >
      <b-row class="m-0">
        <b-col md="12"
               class="p-0"
        >
          <feedback
            :feedback="feedback"
            :problem-statement-id="problemStatementId"
          />
        </b-col>
        <b-col
          v-if="!isPreview"
          class="m-0 p-0"
          md="12"
        >
          <actions
            :has-feedback="hasFeedback"
            :is-processing="isProcessing"
            :level="level ? level : ''"
            :on-submit="submit"
            :disabled="!isValidated"
            :has-minimal-view="hasMinimalView"
            :tooltip-text="
              $t('student-test-module.fill-problem-validation-message')
            "
            :display-result="displayResult"
            :button-title="hasFeedback ? $t('next') : $t('submit')"
            :engine="engine"
            :school-settings="schoolSettings"
            v-on="$listeners"
          />
        </b-col>
      </b-row>
    </portal>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormInput, BImg, BCard,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import { mapGetters } from 'vuex'
import { checkSettings } from '@/utils/visibilitySettings'
import Paragraph from '@/views/common/templates/components/Paragraph.vue'
import ImageQA from '@/views/common/templates/components/ImageQA.vue'
import Shapes from '@/views/common/templates/components/Shapes.vue'
import ImageParagraph from '@/views/common/templates/components/ImageParagraph.vue'
import CenteredCard from '@/views/common/templates/components/CenteredCard.vue'
import Cards from '@/views/common/templates/components/Cards.vue'
import SideCard from '@/views/common/templates/components/SideCard.vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import Actions from './Actions.vue'
import Feedback from './Feedback.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    Feedback,
    Actions,
    Paragraph,
    ImageParagraph,
    ImageQA,
    CenteredCard,
    Cards,
    Shapes,
    SideCard,
    BImg,
    BCard,
    AudioPlayer,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    previewAnswers: {
      type: Array,
      default: () => [],
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
    feedback: {
      type: Object,
      default: () => { },
    },
    displayResult: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: 1,
    },
    feedbackQuestion: {
      type: Array,
      default: () => [],
    },
    problemStatementId: {
      type: [Number, String],
      required: false,
    },
    rules: {
      type: Object,
      default: () => null,
    },
    startTime: {
      type: [String, Date],
      required: false,
      default: () => null,
    },
    engine: {
      type: String,
      default: () => '',
    },
    hasMinimalView: {
      type: Boolean,
      default: false,
    },
    schoolSettings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      placeHolder: '[ ]',
      localQuestions: [],
      isValidated: false,
      answers: {},
      fullHtml: '',
      template: null,
      beforeHtml: '',
      afterHtml: '',
      centralHtml: '',
      defaultShape: 'hexagon.png',
      fibType: 'fib',
    }
  },
  computed: {
    ...mapGetters('problem', {
      validation: 'getValidations',
      shapes: 'getShapes',
    }),
    bodyAudio() {
      return this.questions[0]?.extra_file
    },
    feedbackQuestionComputed() {
      if (this.feedback?.template?.template) {
        return this.feedback?.template?.template
      }
      return this.feedbackQuestion
    },
    getTemplateComponent() {
      if (this.template?.type === 'paragraph') return 'Paragraph'
      if (this.template?.type === 'image-paragraph') return 'ImageParagraph'
      if (this.template?.type === 'image-qa') return 'ImageQA'
      if (this.template?.type === 'shapes') return 'Shapes'
      if (this.template?.type === 'centered-cards') return 'CenteredCard'
      if (this.template?.type === 'cards') return 'Cards'
      if (this.template?.type === 'side-cards') return 'SideCard'
      return ''
    },
    shouldShowHints() {
      // eslint-disable-next-line eqeqeq
      const image = this.rules?.hide_image_hints != '1'
      // eslint-disable-next-line eqeqeq
      const text = this.rules?.hide_text_hints != '1'
      const isHiddenByDistrict = this.$store.getters['studentLab/isProblemHintsHiddenByDistrict'] && this.AUTH_USER()?.usertype !== 'super'
      return {
        // eslint-disable-next-line eqeqeq
        hints: !isHiddenByDistrict && this.rules?.hide_hints != '1' && (image || text),
        image_hints: image,
        text_hints: text,
      }
    },
    hasFeedback() {
      return !!(this.feedback?.text?.length > 0 || this.feedback?.image || this.feedback?.audio || this.feedback?.template)
    },
    getShape() {
      if (this.template?.type !== 'shapes') return ''
      const sh = this.template?.template[0]?.shape ? this.template?.template[0]?.shape : this.defaultShape
      return this.shapes.filter(shape => shape.value === sh)[0]
    },
    getFibType() {
      if (this.template?.type !== 'shapes') return ''
      return this.template?.template[0]?.type ? this.template?.template[0]?.type : this.fibType
    },
    getBgColor() {
      if (this.template?.type !== 'shapes') return ''
      return this.template?.template[0]?.bgColor
    },
    getTemplateOptions() {
      if (this.template?.type !== 'shapes') return ''
      const sh = this.template?.template[0]?.shape ? this.template?.template[0]?.shape : this.defaultShape

      return {
        shape: this.shapes.filter(shape => shape.value === sh)[0],
        type: this.template?.template[0]?.type ? this.template?.template[0]?.type : this.fibType,
        bgColor: this.template?.template[0]?.bgColor,
        textAlign: this.template?.template[0]?.textAlign ? this.template?.template[0]?.textAlign : 'left',
      }
    },
    defaultAnswers() {
      let answers = []
      // eslint-disable-next-line no-unused-expressions
      this.questions?.forEach(question => {
        answers = [...answers, ...question.answers.map(ans => ans.text)]
      })
      // eslint-disable-next-line eqeqeq
      if (this.rules?.disable_random_hints != '1') {
        answers.sort(() => Math.random() - 0.5)
      }
      return answers
    },
    autoPlay() {
      return this.$route.query.autoPlay
    },
    copyPasteEnabled() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const setting = checkSettings('copy_paste', schoolSetting, distSetting)
      if (setting.value === '1') {
        return true
      }
      return false
    },
  },

  watch: {
    question() {
      this.initData()
    },
    validation() {
      this.setValidationClasses()
    },
  },

  mounted() {
    document.addEventListener('input', e => {
      const element = e.target
      if (element.className.includes('answer')) {
        const index = element.getAttribute('data-id')
        this.answers[index] = element.value
        this.checkValidation()
        this.$forceUpdate()
      }
    })
    this.initData().then(() => {
      this.changeInputSize()

      if (this.previewAnswers && this.previewAnswers.length) {
        const inputFields = document.getElementsByTagName('input')
        const answerKeys = Object.keys(this.answers).sort()
        answerKeys.forEach((key, index) => {
          inputFields.forEach(field => {
            if (`${field.getAttribute('data-id')}` === key) field.setAttribute('value', this.previewAnswers[index].input)
          })
          this.answers[key] = this.previewAnswers[index].input
        })

        this.setValidationClasses()
      }
    })
  },

  methods: {

    initData() {
      return new Promise(resolve => {
        try {
          this.template = JSON.parse(this.questions[0].text)
          if (
            this.template?.template?.[0]?.type === 'qa'
            || this.template?.type === 'image-qa'
            || this.template?.type === 'image-paragraph'
          ) {
            this.formatQA(this.template?.template, 'subtitle')
          }

          if (this.template?.before_template) {
            this.beforeHtml += this.formatQuestion(this.template?.before_template, 'before')
          }

          if (this.template?.after_template) {
            this.afterHtml += this.formatQuestion(this.template?.after_template, 'after')
          }
        } catch {
          this.answers = {}
          let question = this.questions[0].text
          question = question.replaceAll(this.placeHolder, `$${this.placeHolder}$`)
          this.localQuestions = question.split('$').filter(e => e.length > 0)
          this.localQuestions.forEach((element, index) => {
            if (element === this.placeHolder) {
              if (this.previewAnswers.length) {
                this.answers[index] = ''
              }
              this.fullHtml += `
                  <input
                    type="text"
                    :id="'Field_' + ${(index + 1)}"
                    data-id="${index}"
                    style="display: inline-block; width: 4%;margin-top:2px"
                    class="mx-1 answer-input answer form-control"
                    :name="'Field_' + ${(index + 1)}"
                    onpaste="return ${this.copyPasteEnabled};"
                  />`
            } else {
              this.fullHtml += element
            }
          })
        } finally {
          resolve()
        }
      })
    },
    formatQuestion(question, dataString) {
      let html = ''
      question
        .replaceAll(this.placeHolder, `##${this.placeHolder}##`)
        .split('##')
        .filter(e => e.length > 0)
        .forEach((element, index) => {
          if (element === this.placeHolder) {
            // [`${dataString}-${index}`] = ''
            html += `
              <input
                type="text"
                :id="'Field_' + ${(index + 1)}"
                data-id="${dataString}-${index}"
                style="display: inline-block; width: 150px;margin-top:2px"
                class="mx-1 answer-input answer form-control"
                :name="'Field_' + ${(index + 1)}"
                onpaste="return ${this.copyPasteEnabled};"
              />
            `
          } else {
            html += element
          }
        })
      return html
    },
    formatQA(question) {
      for (let index = 0; index < question.length; index += 1) {
        // this.answers[`${dataString}-${index}-0`] = ''
      }
    },
    checkValidation() {
      this.isValidated = this.validate()
    },
    validate() {
      const keys = Object.keys(this.answers)
      for (let i = 0; i < keys.length; i += 1) {
        if (this.answers[keys[i]] === '' && keys[i] !== 'subtitle-0-0') return false
      }
      return true
    },
    sortAnswerByKey(ansObj) {
      // eslint-disable-next-line radix
      const extractNumber = key => parseInt(key.split('-')[1])

      // Sorting the object keys based on the extracted number
      const sortedKeys = Object.keys(ansObj).sort((a, b) => extractNumber(a) - extractNumber(b))

      // Creating a new object with the sorted keys
      const sortedAnswers = []
      sortedKeys.forEach(key => {
        sortedAnswers.push(key)
      })
      return sortedAnswers
    },
    submit() {
      if (this.autoPlay) {
        this.$emit('submitQuestion', { lang_level: this.questions[0].lang_level, answer: this.answers })
      } else {
        this.$refs.submitQuestionForm.validate().then(success => {
          if (success) {
            const answers = []
            this.sortAnswerByKey(this.answers).forEach(key => {
              answers.push(this.answers[key])
            })
            this.$emit('submitQuestion', { lang_level: this.questions[0].lang_level, answer: answers })
          }
        })
      }
    },
    setValidationClasses() {
      const answers = document.querySelectorAll('.answer')
      answers.forEach((answer, index) => {
        let isCorrect
        if (this.validation.length) isCorrect = this.validation[index]?.is_correct
        else isCorrect = this.previewAnswers[index]?.is_correct
        if (!answer.value && this.previewAnswers[index]?.input) {
          // eslint-disable-next-line no-param-reassign
          answer.value = this.previewAnswers[index]?.input
        }
        answer.classList.add(`answer-${isCorrect}`)
      })
    },
    clearValidationClasses() {
      const answers = document.querySelectorAll('.answer')
      answers.forEach(answer => {
        if (answer.classList.includes('answer-0')) answer.classList.remove('answer-0')
        if (answer.classList.includes('answer-1')) answer.classList.remove('answer-1')
      })
    },
    changeInputSize() {
      const questions = document.querySelectorAll('.answer-input')
      const [{ answers }] = this.questions
      const selectedAnswers = [...answers]
      selectedAnswers.sort((a, b) => a.id - b.id)
      questions.forEach((question, index) => {
        const maxSize = selectedAnswers[index]?.text?.length
        /* eslint-disable no-param-reassign */
        question.style.width = maxSize < 5 ? `${maxSize + 2.2}vw` : `${maxSize / 1.2 + 1.2}vw`
      })
    },
  },
}
</script>

<style lang="scss">
.inline-p {
  display: inline-block;
}
.answer-1 {
  border: 2px solid #2eb82e !important;
}
.answer-0 {
  border: 2px solid #df0000 !important;
}
.fill-row {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
<style>
.ck-content .card-body  img {
  width: 100%;
}
</style>
