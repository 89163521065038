export default {
  namespaced: true,
  state: {
    activeViewOfLab: '',
    studentAnswer: '',
    districtSetting: [],
  },
  getters: {
    isProblemHintsHiddenByDistrict(state) {
      // eslint-disable-next-line eqeqeq
      return state.districtSetting.find(i => i.setting_name === 'hide_problem_hints')?.value == '1'
    },
  },
  mutations: {
    SET_ACTIVE_LAB_VIEW(state, payload) {
      state.activeViewOfLab = payload
    },
    SET_STUDENT_ANSWER(state, payload) {
      state.studentAnswer = payload
    },
    SET_DISTRICT_SETTINGS(state, payload) {
      state.districtSetting = payload
    },
  },
}
