<template>
  <div>
    <b-row>
      <b-col md="6">

        <b-form-group
          :label="$t('class-module.grade')"
        >
          <b-form-select
            v-model="form.gradeId"
            :options="gradesLists"
            value-field="id"
            text-field="name"
            @change="getCourses"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          :label="$t('labels.course')"
        >
          <b-form-select
            v-model="form.courseId"
            :options="filteredCourses"
            :disabled="!form.gradeId"
            value-field="id"
            text-field="name"
            @change="getLessonGroups"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          :label="$t('labels.lesson-group')"
        >
          <b-form-select
            v-model="form.lessonGroupId"
            :disabled="!form.courseId"
            :options="lessonGroups"
            @change="getLessons"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          :label="$t('labels.lesson')"
        >
          <b-form-select
            v-model="form.lessonId"
            :disabled="!form.lessonGroupId"
            :options="lessons"
            @change="$emit('lessonChange', form.lessonId)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BRow, BCol, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
  },
  props: {
    classId: {
      type: Number,
      default: 0,
    },
    form: {
      type: Object,
      default: () => ({
        gradeId: null,
        courseId: null,
        lessonGroupId: null,
        lessonId: null,
      }),
    },
  },
  data() {
    return {
      isLoading: true,
      gradesLists: [],
      courses: [],
      filteredCourses: [],
      lessonGroups: [],
      lessons: [],
    }
  },
  mounted() {
    this.getGrades()
  },
  methods: {
    reInitialize() {
      if (this.form.courseId && this.form.lessonGroupId && this.form.lessonId) {
        const gradeId = this.gradesLists.find(item => !!item.courses.find(i => +i.id === +this.form.courseId))?.id
        if (gradeId) {
          this.form.gradeId = gradeId
          this.getCourses()
          this.getLessonGroups(this.form.courseId)
          this.getLessons(this.form.lessonGroupId)
        }
      }
    },
    getCourses() {
      this.filteredCourses = this.gradesLists.find(item => item.id === this.form.gradeId)?.courses || []
    },
    getLessonGroups(courseId) {
      useJwt.getLessonGroupByCourse({
        params: { cid: courseId },
      }).then(res => {
        const data = res.data.data.data.map(({ id, name }) => ({
          id,
          value: id,
          text: name,
        }))
        data.unshift({ value: null, text: i18n.tc('requests.select-lesson-group') })
        this.lessonGroups = data
      }).catch(err => {
        console.error(err)
      })
    },
    getLessons(gid) {
      useJwt.getLesson(gid).then(res => {
        const data = res.data.data.data.map(({ id, name }) => ({
          id,
          value: id,
          text: name,
        }))
        data.unshift({ value: null, text: i18n.tc('requests.select-lesson') })
        this.lessons = data
      }).catch(err => {
        console.error(err)
      })
    },
    getGrades() {
      this.isLoading = true
      useJwt.getGradeLists(this.classId).then(response => {
        this.gradesLists = [...response.data.data?.grades]
        let filteredCourse = []
        this.gradesLists.forEach(grade => {
          filteredCourse = [...filteredCourse, ...(grade.courses || [])]
        })
        this.courses = filteredCourse
        this.reInitialize()
      }).catch(error => {
        this.showError('Failed to load grades')
        console.error(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
