/* eslint-disable import/no-cycle */
import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Vue from 'vue'
import { getUserData } from '@/auth/utils'
import jwtDefaultConfig from './jwtDefaultConfig'
import JwtServiceMore from './JwtServiceMore'

export default class JwtService extends JwtServiceMore {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    super()
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          const userData = getUserData()
          if (userData && userData.isGuestUser) {
            // eslint-disable-next-line no-param-reassign
            config.headers['Guest-User'] = true
          }
        }
        return config
      },
      error => Promise.reject(error),
    )

    // interceptor error toasts
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // if response has validation errors
        // return validaiton errors
        // donot show toast
        if (error.response?.data?.errors) {
          return Promise.reject(error)
        }
        // if unauthorized return to login page
        // do not show toasts
        if (error.response?.status === 401) {
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
          localStorage.removeItem('userData')
          router.push('/login')
        }
        // if 404 show not found page
        // do not show toasts
        // if (error.response?.status === 404) {
        //   router.push('/error-404')
        // }

        return Promise.reject(error)
      },
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  forgotPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.forgotPasswordEndpoint, ...args)
  }

  resetPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.resetPasswordEndpoint, ...args)
  }

  checkTokenValidity(args) {
    return this.axiosIns.get(this.jwtConfig.resetPasswordEndpoint, { params: args })
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  logout(...args) {
    return this.axiosIns.post(this.jwtConfig.logoutEndpoint, ...args, {
      accessToken: this.getToken(),
    })
  }

  verifyEmail(args) {
    return this.axiosIns.get(this.jwtConfig.verifyEmailEndpoint, { params: args })
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  socialLogin(provider, ...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.loginEndpoint}/${provider}/callback`,
      ...args,
    )
  }

  userShow(...args) {
    return this.axiosIns.get(this.jwtConfig.meShow, ...args)
  }

  userUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.meUpdate, ...args)
  }

  resendVerifyEmail(...args) {
    return this.axiosIns.get(this.jwtConfig.resendVerifyEmail, ...args)
  }

  changePassword(...args) {
    return this.axiosIns.post(this.jwtConfig.changePassword, ...args)
  }

  avatarUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.avatarUpdate, ...args)
  }

  setLanguage(...args) {
    return this.axiosIns.post(this.jwtConfig.setLanguage, ...args)
  }

  createUser(isParent = false, ...args) {
    const endpoint = isParent ? `${this.jwtConfig.usersEndpoint}?make_parent` : this.jwtConfig.usersEndpoint
    return this.axiosIns.post(endpoint, ...args)
  }

  attachChildUsers(...args) {
    return this.axiosIns.post(`${this.jwtConfig.attachChildUsersEndpoint}`, ...args)
  }

  getAllChildUsers(type, id) {
    return this.axiosIns.get(`${this.jwtConfig.childUsersEndpoint}/${type}?userId=${id}&child=true`)
  }

  getAllParentUsers(type, id) {
    return this.axiosIns.get(`${this.jwtConfig.childUsersEndpoint}/${type}?userId=${id}&parent=true`)
  }

  getUsers(...args) {
    return this.axiosIns.get(this.jwtConfig.usersEndpoint, ...args)
  }

  getUser(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.usersEndpoint}/${id}`, ...args)
  }

  getUsersOfType(type, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.usersEndpoint}/type/${type}`, ...args)
  }

  getChildUserTypes(...args) {
    return this.axiosIns.get(this.jwtConfig.childUserEndpoint, ...args)
  }

  getPermissionsForUserType(type, ...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.userEndpoint}/${type}/permissions`,
      ...args,
    )
  }

  updateUser(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.usersEndpoint}/${id}`, ...args)
  }

  deleteUser(id, permanently) {
    return permanently ? this.axiosIns.delete(`${this.jwtConfig.usersEndpoint}/${id}?force=true`) : this.axiosIns.delete(`${this.jwtConfig.usersEndpoint}/${id}`)
  }

  createSubUser(...args) {
    return this.axiosIns.post(this.jwtConfig.subUserEndpoint, ...args)
  }

  getSubUsers(...args) {
    return this.axiosIns.get(this.jwtConfig.subUserEndpoint, ...args)
  }

  getSubUser(id, ...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.subUserEndpoint}/${id}`,
      ...args,
    )
  }

  updateSubUser(id, ...args) {
    return this.axiosIns.put(
      `${this.jwtConfig.subUserEndpoint}/${id}`,
      ...args,
    )
  }

  resetUserPassword(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.userEndpoint}/${id}/reset-password`, ...args)
  }

  getGroups(...args) {
    return this.axiosIns.get(`${this.jwtConfig.coursesEndpoint}/${args[0].id}`)
  }

  getLessons(...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.lessonGroupEndpoint}/${args[0].id}`,
    )
  }

  getLessonGroupByCourse(...args) {
    return this.axiosIns.get(this.jwtConfig.lessonGroupEndpoint, ...args)
  }

  createCourse(...args) {
    return this.axiosIns.post(this.jwtConfig.coursesEndpoint, ...args)
  }

  deleteCourse(id) {
    return this.axiosIns.delete(`${this.jwtConfig.coursesEndpoint}/${id}`)
  }

  updateCourse(id, ...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.coursesEndpoint}/${id}`,
      ...args,
    )
  }

  searchCourses(term) {
    return this.axiosIns.get(`${this.jwtConfig.coursesEndpoint}/search/${term}`)
  }

  getLessonGroup(id) {
    return this.axiosIns.get(`${this.jwtConfig.lessonGroupEndpoint}/${id}`)
  }

  getLesson(id, standardId, page = 1, perPage = 99999, isConversationalMode) {
    return standardId && standardId.length > 0 ? this.axiosIns.post(`${this.jwtConfig.getStandardLessons}`, { standardId }) : this.axiosIns.get(`${this.jwtConfig.lessonsEndpoint}?gid=${id}&page=${page}&perPage=${perPage}&isConversationalMode=${isConversationalMode ? 1 : 0}`)
  }

  createLesson(...args) {
    return this.axiosIns.post(this.jwtConfig.lessonsEndpoint, ...args)
  }

  deleteLesson(id) {
    return this.axiosIns.delete(`${this.jwtConfig.lessonsEndpoint}/${id}`)
  }

  updateLesson(id, ...args) {
    return this.axiosIns.put(
      `${this.jwtConfig.lessonsEndpoint}/${id}`,
      ...args,
    )
  }

  createLessonGroup(...args) {
    return this.axiosIns.post(this.jwtConfig.lessonGroupEndpoint, ...args)
  }

  updateLessonGroup(id, ...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.lessonGroupEndpoint}/${id}`,
      ...args,
    )
  }

  deleteLessonGroup(id) {
    return this.axiosIns.delete(`${this.jwtConfig.lessonGroupEndpoint}/${id}`)
  }

  getListOfStudents(...args) {
    return this.axiosIns.get(this.jwtConfig.listStudentsEndpoint, ...args)
  }

  getStudentsForClass(id, { page = 1, limit = 30, search = '' }) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${id}/get-students?page=${page}&limit=${limit}&search=${search}`)
  }

  getCourses(id, ...args) {
    return id && id.length > 0 ? this.axiosIns.post(`${this.jwtConfig.getCoursesFromSubjectEndpoint}`, { subjectId: id }) : this.axiosIns.get(this.jwtConfig.coursesEndpoint, ...args)
  }

  getListOfTeachers(...args) {
    return this.axiosIns.get(this.jwtConfig.listTeachersEndpoint, ...args)
  }

  getClasses(...args) {
    return this.axiosIns.get(this.jwtConfig.classEndpoint, ...args)
  }

  getClass(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${id}`, ...args)
  }

  moveStudent(classId, userId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.classEndpoint}/${classId}/move/user/${userId}`, ...args)
  }

  createClass(...args) {
    return this.axiosIns.post(this.jwtConfig.classEndpoint, ...args)
  }

  updateClass(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.classEndpoint}/${id}`, ...args)
  }

  updateClassLink(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.classEndpoint}/${id}/link`, ...args)
  }

  deleteClass(id, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.classEndpoint}/${id}`, ...args)
  }

  getStudentsOfClass(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${id}/students`, ...args)
  }

  getStudentsWithScoreboards(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/scoreboards`)
  }

  addStudentToClass(id, ...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.classEndpoint}/${id}/students`,
      ...args,
    )
  }

  getTeachersOfClass(id) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${id}/teachers`)
  }

  removeStudentFromClass(classId, studentId) {
    return this.axiosIns.post(
      `${this.jwtConfig.classEndpoint}/${classId}/students/${studentId}`,
    )
  }

  inviteParent(...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.inviteParentEndpoint}`,
      ...args,
    )
  }

  updateTeacherCourses(...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.teacherCourseEndpoint}`,
      ...args,
    )
  }

  getTeacherCourses(...args) {
    return this.axiosIns.get(`${this.jwtConfig.teacherCourseEndpoint}`, ...args)
  }

  getTeacherProfile(id) {
    return this.axiosIns.get(`${this.jwtConfig.teacherProfileEndpoint}/${id}`)
  }

  updateTeacherProfile(teacherProfileId, ...args) {
    return this.axiosIns.put(
      `${this.jwtConfig.teacherProfileEndpoint}/update/${teacherProfileId}`,
      ...args,
    )
  }

  getProfile() {
    return this.axiosIns.get(`${this.jwtConfig.profileEndpoint}`)
  }

  updateProfile(profileId, ...args) {
    return this.axiosIns.put(
      `${this.jwtConfig.profileEndpoint}/${profileId}`,
      ...args,
    )
  }

  getArticle(...args) {
    return this.axiosIns.get(`${this.jwtConfig.article}`, ...args)
  }

  getSingleArticle(id) {
    return this.axiosIns.get(`${this.jwtConfig.article}/${id}`)
  }

  getGrade() {
    return this.axiosIns.get(`${this.jwtConfig.grade}`)
  }

  createGrade(...args) {
    return this.axiosIns.post(this.jwtConfig.grade, ...args)
  }

  updateGrade(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.grade}/${id}`, ...args)
  }

  deleteGrade(id) {
    return this.axiosIns.delete(`${this.jwtConfig.grade}/${id}`)
  }

  getSubject() {
    return this.axiosIns.get(`${this.jwtConfig.subject}`)
  }

  getSubjectsOfGrade(id) {
    return this.axiosIns.get(`${this.jwtConfig.subject}?gid=${id}`)
  }

  getStandardSubjectsOfGrade(id, filterGrade = false) {
    return filterGrade ? this.axiosIns.post(this.jwtConfig.getGradeStandardSubjectEndpoint, { gradeId: id }) : this.axiosIns.get(`${this.jwtConfig.gradeStandardSubject}/${id}`)
  }

  createSubject(...args) {
    return this.axiosIns.post(this.jwtConfig.subject, ...args)
  }

  updateSubject(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.subject}/${id}`, ...args)
  }

  deleteSubject(id) {
    return this.axiosIns.delete(`${this.jwtConfig.subject}/${id}`)
  }

  getNetworks(...args) {
    return this.axiosIns.get(this.jwtConfig.networkEndpoint, ...args)
  }

  destroyNetwork(id, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.networkEndpoint}/${id}`, ...args)
  }

  getLessonPages(...args) {
    return this.axiosIns.get(this.jwtConfig.lessonPagesEndpoint, ...args)
  }

  getLessonPage(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.lessonPagesEndpoint}/${id}`, ...args)
  }

  createLessonPage(...args) {
    return this.axiosIns.post(this.jwtConfig.lessonPagesEndpoint, ...args)
  }

  updateLessonPage(id, ...args) {
    return this.axiosIns.put(
      `${this.jwtConfig.lessonPagesEndpoint}/${id}`,
      ...args,
    )
  }

  deleteLessonPage(id) {
    return this.axiosIns.delete(`${this.jwtConfig.lessonPagesEndpoint}/${id}`)
  }

  getMyNetworks() {
    return this.axiosIns.get(`${this.jwtConfig.networkEndpoint}?my`)
  }

  showNetwork(id) {
    return this.axiosIns.get(`${this.jwtConfig.networkEndpoint}/${id}`)
  }

  createNetwork(...args) {
    return this.axiosIns.post(this.jwtConfig.networkEndpoint, ...args)
  }

  updateNetwork(id, ...args) {
    return this.axiosIns.put(
      `${this.jwtConfig.networkEndpoint}/${id}`,
      ...args,
    )
  }

  deleteNetwork(id) {
    return this.axiosIns.delete(`${this.jwtConfig.networkEndpoint}/${id}`)
  }

  forceDeleteNetwork(id) {
    return this.axiosIns.delete(`${this.jwtConfig.networkEndpoint}/${id}/force`)
  }

  restoreNetwork(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.networkEndpoint}/${id}/restore`, ...args)
  }

  getNetworkMembers(id) {
    return this.axiosIns.get(`${this.jwtConfig.networkEndpoint}/${id}/members`)
  }

  getMyNetworkMemberShip(networkId) {
    return this.axiosIns.get(
      `${this.jwtConfig.networkEndpoint}/${networkId}/member/status`,
    )
  }

  getNetworkArticles(id, ...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.networkEndpoint}/${id}/articles`,
      ...args,
    )
  }

  getNetworkUnverifiedArticles(id, ...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.networkEndpoint}/${id}/articles/unverified`,
      ...args,
    )
  }

  deleteArticleFromNetwork(articleId, networkId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.networkEndpoint}/${networkId}/article/${articleId}/remove`, ...args)
  }

  deleteArticlePermanently(articleId, networkId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.networkEndpoint}/${networkId}/article/${articleId}/delete`, ...args)
  }

  removeUserFromNetwork(networkId, userId) {
    return this.axiosIns.post(
      `${this.jwtConfig.networkEndpoint}/${networkId}/member/${userId}/remove`,
    )
  }

  updateNetworkLogo(networkId, ...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.networkEndpoint}/${networkId}/logo/update`,
      ...args,
    )
  }

  searchForUsers(...args) {
    return this.axiosIns.get(`${this.jwtConfig.userEndpoint}/search`, ...args)
  }

  requestJoinNetwork(...args) {
    return this.axiosIns.post(`${this.jwtConfig.joinRequestEndpoint}`, ...args)
  }

  getMemberRequests(networkId, ...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.networkEndpoint}/${networkId}/member/pending?type=request`,
      ...args,
    )
  }

  getNetworkCoLeaders(networkId, ...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.networkEndpoint}/${networkId}/coleaders`,
      ...args,
    )
  }

  acceptRequest(...args) {
    return this.axiosIns.post(this.jwtConfig.requestAcceptEndpoint, ...args)
  }

  rejectRequest(...args) {
    return this.axiosIns.post(this.jwtConfig.requestRejectEndpoint, ...args)
  }

  rejectArticleRequest(...args) {
    return this.axiosIns.post(`${this.jwtConfig.requestRejectEndpoint}/article`, ...args)
  }

  inviteMemberToNetwork(...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.networkEndpoint}/invite`,
      ...args,
    )
  }

  promoteUser(...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.networkEndpoint}/invite?promote`,
      ...args,
    )
  }

  createArticleForNetwork(nid, ...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.articleEndpoint}?nid=${nid}`,
      ...args,
    )
  }

  editArticle(id, ...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.articleEndpoint}/${id}`,
      ...args,
    )
  }

  deleteArticle(id) {
    return this.axiosIns.delete(`${this.jwtConfig.articleEndpoint}/${id}`)
  }

  changeFeaturedArticle(id) {
    return this.axiosIns.post(`${this.jwtConfig.changeFeaturedArticle}/${id}`)
  }

  getNetworkInvitations(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.networkEndpoint}/${id}/member/pending?type=invite`, ...args)
  }

  cancelInvitation(...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.cancelRequestEndoint}`,
      ...args,
    )
  }

  cancelNetworkJoinRequest(id, ...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.networkEndpoint}/${id}/request/cancel`,
      ...args,
    )
  }

  createStandard(...args) {
    return this.axiosIns.post(`${this.jwtConfig.standard}`, ...args)
  }

  updateStandard(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.standard}/${id}`, ...args)
  }

  deleteStandard(id) {
    return this.axiosIns.delete(`${this.jwtConfig.standard}/${id}`)
  }

  getGradeSubjects(id) {
    return this.axiosIns.get(`${this.jwtConfig.gradeSubject}/${id}`)
  }

  createGradeSubjects(...args) {
    return this.axiosIns.post(`${this.jwtConfig.gradeSubject}`, ...args)
  }

  updateGradeSubjects(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.gradeSubject}/${id}`, ...args)
  }

  deleteGradeSubjects(id) {
    return this.axiosIns.delere(`${this.jwtConfig.gradeSubject}/${id}`)
  }

  getStandardGrades(args) {
    return this.axiosIns.get(`${this.jwtConfig.standardGrade}`, { params: { ...args } })
  }

  createStandardGrade(...args) {
    return this.axiosIns.post(`${this.jwtConfig.standardGradeEndpoint}`, ...args)
  }

  updateStandardGrade(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.standardGradeEndpoint}/${id}`, ...args)
  }

  deleteStandardGrade(id) {
    return this.axiosIns.delete(`${this.jwtConfig.standardGradeEndpoint}/${id}`)
  }

  getDomain(args) {
    return this.axiosIns.get(`${this.jwtConfig.domain}`, { params: { ...args } })
  }

  getDomainBySubject(args) {
    return this.axiosIns.get(`${this.jwtConfig.domain}`, { params: { ...args } })
  }

  getDomainByLesson(id) {
    return this.axiosIns.get(`${this.jwtConfig.getDomainByLesson}/${id}`)
  }

  searchDomain(...args) {
    return this.axiosIns.post(`${this.jwtConfig.domain}/search`, ...args)
  }

  createDomain(...args) {
    return this.axiosIns.post(`${this.jwtConfig.domain}`, ...args)
  }

  deleteDomain(id) {
    return this.axiosIns.delete(`${this.jwtConfig.domain}/${id}`)
  }

  updateDomain(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.domain}/${id}`, ...args)
  }

  getCefrSubject() {
    return this.axiosIns.get(`${this.jwtConfig.subject}/cerf`)
  }

  getCefrDomain(id) {
    return this.axiosIns.get(`${this.jwtConfig.domain}/cerf/${id}`)
  }

  uploadBulkDomain(subjectId, data, config) {
    return this.axiosIns.post(`${this.jwtConfig.domain}/bulk/${subjectId}`, data, config)
  }

  orderStandardSubject(subjectId, data, config) {
    return this.axiosIns.put(`${this.jwtConfig.domain}/order/${subjectId}`, data, config)
  }

  getCefrStandard(id) {
    return this.axiosIns.get(`${this.jwtConfig.domainStandard}/cerf/${id}`)
  }

  updateCefrStandard(statementId, standardId) {
    return this.axiosIns.put(`${this.jwtConfig.statementEndpoint}/${statementId}/standard/${standardId}/cerf`)
  }

  getStandards(args) {
    return this.axiosIns.get(`${this.jwtConfig.standard}`, { params: { ...args } })
  }

  getStandardsByDomain(id, filterDomain = false) {
    return filterDomain ? this.axiosIns.post(this.jwtConfig.getStandardOfDomains, { domainId: id }) : this.axiosIns.get(`${this.jwtConfig.getDomainStandard}/${id}`)
  }

  searchStandard(...args) {
    return this.axiosIns.post(`${this.jwtConfig.standard}/search`, ...args)
  }

  getStandardSubjects(args) {
    return this.axiosIns.get(`${this.jwtConfig.standardSubject}`, { params: { ...args } })
  }

  searchStandardSubject(...args) {
    return this.axiosIns.post(`${this.jwtConfig.standardSubject}/search`, ...args)
  }

  createStandardSubject(...args) {
    return this.axiosIns.post(`${this.jwtConfig.standardSubject}`, ...args)
  }

  updateStandardSubject(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.standardSubject}/${id}`, ...args)
  }

  deleteStandardSubject(id) {
    return this.axiosIns.delete(`${this.jwtConfig.standardSubject}/${id}`)
  }

  createQuestions(args, config) {
    return this.axiosIns.post(`${this.jwtConfig.questionEndpoint}`, args, config)
  }

  editProblem(...args) {
    return this.axiosIns.post(`${this.jwtConfig.questionEndpoint}`, ...args)
  }

  changeVisibility(...args) {
    return this.axiosIns.post(`${this.jwtConfig.problemVisibilityEndpoint}`, ...args)
  }

  updateLessonEngine(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.lessonsEndpoint}/${id}/engine/update`, ...args)
  }

  updateClassroomLessonEngine(args) {
    return this.axiosIns.post(`${this.jwtConfig.lessonsEndpoint}/engine/update`, args)
  }

  updateTheme(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.problemEndpoint}/${id}/theme`, ...args)
  }

  getProblemByLesson(...args) {
    return this.axiosIns.get(`${this.jwtConfig.problemEndpoint}`, ...args)
  }

  getProblem(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.problemEndpoint}/${id}`, ...args)
  }

  updateProblem(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.problemEndpoint}/${id}`, ...args)
  }

  updateProblemType(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.problemEndpoint}/${id}/type`, ...args)
  }

  updateProblemStatement(id, args, config) {
    return this.axiosIns.post(
      `${this.jwtConfig.statementEndpoint}/${id}`,
      args,
      config,
    )
  }

  updateQuestion(id, args, config) {
    return this.axiosIns.post(
      `${this.jwtConfig.questionEndpoint}/${id}`,
      args,
      config,
    )
  }

  updateAnswer(id, args, config) {
    return this.axiosIns.post(`${this.jwtConfig.answerEndpoint}/${id}`, args, config)
  }

  updateAnswerCorrection(id, args) {
    return this.axiosIns.post(`${this.jwtConfig.answerCorrectionEndpoint}/${id}`, args)
  }

  deleteProblem(id) {
    return this.axiosIns.delete(`${this.jwtConfig.problemEndpoint}/${id}`)
  }

  deleteMultipleProblem(args) {
    return this.axiosIns.post(`${this.jwtConfig.problemEndpoint}/delete/bulk`, args)
  }

  forceDeleteProblem(id) {
    return this.axiosIns.delete(`${this.jwtConfig.problemEndpoint}/${id}/delete/force`)
  }

  deleteAnswer(id) {
    return this.axiosIns.delete(`${this.jwtConfig.answerEndpoint}/${id}`)
  }

  deleteQuestion(id) {
    return this.axiosIns.delete(`${this.jwtConfig.questionEndpoint}/${id}`)
  }

  addAnswer(args, config) {
    return this.axiosIns.post(`${this.jwtConfig.answerEndpoint}/add/more`, args, config)
  }

  addQuestion(args, config) {
    return this.axiosIns.post(
      `${this.jwtConfig.questionEndpoint}/add/more`,
      args,
      config,
    )
  }

  resendTeacherRequestEmail(...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.classEndpoint}/resend`,
      ...args,
    )
  }

  getProblems(...args) {
    return this.axiosIns.get(`${this.jwtConfig.problemEndpoint}`, ...args)
  }

  getProblemForLesson(lid, ...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.lessonsEndpoint}/${lid}/problem`,
      ...args,
    )
  }

  getDemoProblemForLesson(level, ...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.getDemoEndpoint}/lesson/problem?level=${level}`, ...args,
    )
  }

  getQuestionsForProblem(id, ...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.problemEndpoint}/${id}/questions`,
      ...args,
    )
  }

  checkProblemSolution(id, args, config) {
    return this.axiosIns.post(
      `${this.jwtConfig.problemEndpoint}/${id}/check`,
      args,
      config,
    )
  }

  checkTextSelectionSolution(args, config) {
    return this.axiosIns.post(
      `${this.jwtConfig.checkEndpoint}`,
      args,
      config,
    )
  }

  getLessonsForCourse(id, ...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.coursesEndpoint}/${id}/lessons`,
      ...args,
    )
  }

  getLessonsForCourses(...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.getAllLessonsEndpoint}`,
      ...args,
    )
  }

  getLessonEntryData(id, ...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.lessonsEndpoint}/${id}/entry`,
      ...args,
    )
  }

  retakeTestForLesson(id, ...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.lessonsEndpoint}/${id}/try`,
      ...args,
    )
  }

  getCourseResult(id, ...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.coursesEndpoint}/${id}/result`,
      ...args,
    )
  }

  getCriteria(type) {
    return this.axiosIns.get(`${this.jwtConfig.criteria}/${type}`)
  }

  submitRating(...args) {
    return this.axiosIns.post(`${this.jwtConfig.rating}`, ...args)
  }

  getStudentRating(id) {
    return this.axiosIns.get(`${this.jwtConfig.studentRating}/${id}`)
  }

  getTeacherRating(id) {
    return this.axiosIns.get(`${this.jwtConfig.teacherProfileEndpoint}/${id}`)
  }

  getCourseRating(id) {
    return this.axiosIns.get(`${this.jwtConfig.coursesEndpoint}/${id}`)
  }

  startLessonTest(id, ...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.lessonsEndpoint}/${id}/start`,
      ...args,
    )
  }

  getWordNet(...args) {
    return this.axiosIns.post(this.jwtConfig.getWordNetEndpoint, ...args)
  }

  storeWordNet(...args) {
    return this.axiosIns.post(`${this.jwtConfig.storeWordNetEndpoint}`, ...args)
  }

  getWordNetsForPage(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.getWordsForPage}/${id}`, ...args)
  }

  setWordNetForPage(...args) {
    return this.axiosIns.post(this.jwtConfig.setWordNetForPage, ...args)
  }

  deleteWordNet(...args) {
    return this.axiosIns.post(this.jwtConfig.deleteWordNet, ...args)
  }

  // Course Wordnet Section
  getCourseWordNet(...args) {
    return this.axiosIns.post(this.jwtConfig.getCourseWordnet, ...args)
  }

  storeCourseWordNet(...args) {
    return this.axiosIns.post(`${this.jwtConfig.storeCourseWordnet}`, ...args)
  }

  getCourseWordNetsForPage(...args) {
    return this.axiosIns.post(`${this.jwtConfig.getCourseWordsForPage}`, ...args)
  }

  getCourseWordNetsArray(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.getCourseWordnetArray}/${id}`, ...args)
  }

  setCourseWordNetForPage(...args) {
    return this.axiosIns.post(this.jwtConfig.setCourseWordNet, ...args)
  }

  deleteCourseWordNet(...args) {
    return this.axiosIns.post(this.jwtConfig.deleteCourseWordnet, ...args)
  }

  getAjaxAutoComplete(...args) {
    return this.axiosIns.post(this.jwtConfig.ajaxAutoComplete, ...args)
  }

  getTranslatedWords(...args) {
    return this.axiosIns.post(this.jwtConfig.getTranslatedWords, ...args)
  }

  getNotifications(...args) {
    return this.axiosIns.get(`${this.jwtConfig.notificationEndpoint}`, ...args)
  }

  markNotificationAsRead(...args) {
    return this.axiosIns.post(`${this.jwtConfig.notificationEndpoint}`, ...args)
  }

  deleteNotification(id) {
    return this.axiosIns.delete(`${this.jwtConfig.notificationEndpoint}/${id}`)
  }

  getClassStats(id, userId, ...args) {
    return userId ? this.axiosIns.get(`${this.jwtConfig.classStatsEndpoint}/${id}?userId=${userId}`, ...args) : this.axiosIns.get(`${this.jwtConfig.classStatsEndpoint}/${id}`, ...args)
  }

  getStudentStats(classId, studentId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.classStatsEndpoint}/${classId}/user/${studentId}/lessons`, ...args)
  }

  getStudentLessonStats(classId, studentId, lessonId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.classStatsEndpoint}/${classId}/user/${studentId}/lesson/${lessonId}/tests`, ...args)
  }

  getStudentLessonStatsByLoader(loaderId) {
    return this.axiosIns.get(`${this.jwtConfig.classStatsEndpoint}/${loaderId}/loader`)
  }

  getCalendarEvents(...args) {
    return this.axiosIns.get(`${this.jwtConfig.calendarEventEndpoint}`, ...args)
  }

  getStudentEvents(...args) {
    return this.axiosIns.get(`${this.jwtConfig.studentEventEndpoint}`, ...args)
  }

  createCalendarEvent(...args) {
    return this.axiosIns.post(`${this.jwtConfig.calendarEventEndpoint}`, ...args)
  }

  createStudentEvent(...args) {
    return this.axiosIns.post(`${this.jwtConfig.studentEventEndpoint}`, ...args)
  }

  updateCalendarEvent(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.calendarEventEndpoint}/${id}`, ...args)
  }

  updateStudentEvent(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.studentEventEndpoint}/${id}`, ...args)
  }

  deleteCalendarEvent(id, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.calendarEventEndpoint}/${id}`, ...args)
  }

  deleteStudentEvent(id, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.studentEventEndpoint}/${id}`, ...args)
  }

  getAllLessons() {
    return this.axiosIns.get(this.jwtConfig.allLessonsEndpoint)
  }

  updateOrder(...args) {
    return this.axiosIns.post(this.jwtConfig.updateOrderEndpoint, ...args)
  }

  createLearningAlgorithm(...args) {
    return this.axiosIns.post(this.jwtConfig.adaptiveLearningEndpoint, ...args)
  }

  getLearningAlgorithms(...args) {
    return this.axiosIns.get(this.jwtConfig.adaptiveLearningEndpoint, ...args)
  }

  deleteLearningAlgorithm(id, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.adaptiveLearningEndpoint}/${id}`, ...args)
  }

  getLearningAlgorithm(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.adaptiveLearningEndpoint}/${id}`, ...args)
  }

  updateLearningAlgorithm(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.adaptiveLearningEndpoint}/${id}`, ...args)
  }

  storeTemplate(...args) {
    return this.axiosIns.post(this.jwtConfig.problemTemplatesEndpoint, ...args)
  }

  getTemplates(...args) {
    return this.axiosIns.get(this.jwtConfig.problemTemplatesEndpoint, ...args)
  }

  getTemplate(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.problemTemplatesEndpoint}/${id}`, ...args)
  }

  uploadImage(...args) {
    return this.axiosIns.get('/v1/editor/image/upload', ...args)
  }

  storeVideo(...args) {
    return this.axiosIns.post(this.jwtConfig.problemVideoEndpoint, ...args)
  }

  updateVideo(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.problemVideoEndpoint}/${id}`, ...args)
  }

  updateTimestamp(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.problemVideoTimestampEndpoint}/${id}`, ...args)
  }

  // Chat section
  getChatRoom(...args) {
    return this.axiosIns.get(`${this.jwtConfig.chatRoomEndpoint}`, ...args)
  }

  getGroupChatRoom(...args) {
    return this.axiosIns.post(`${this.jwtConfig.chatGroupRoomEndpoint}`, ...args)
  }

  storeChatRoom(...args) {
    return this.axiosIns.post(`${this.jwtConfig.chatRoomEndpoint}`, ...args)
  }

  showChatRoom(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.chatRoomEndpoint}/${id}`, ...args)
  }

  updateChatRoom(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.chatRoomEndpoint}/${id}`, ...args)
  }

  deleteChatRoom(id) {
    return this.axiosIns.delete(`${this.jwtConfig.chatRoomEndpoint}/${id}`)
  }

  getTotalUseenMsgs(...args) {
    return this.axiosIns.get(`${this.jwtConfig.totalUseenMsgs}`, ...args)
  }

  messageTyping(...args) {
    return this.axiosIns.post(`${this.jwtConfig.messageTyping}`, ...args)
  }

  getVibes(...args) {
    return this.axiosIns.get(`${this.jwtConfig.vibes}`, ...args)
  }

  getCurriculumPages(...args) {
    return this.axiosIns.get(`${this.jwtConfig.curriculumPagesEndpoint}`, ...args)
  }

  getCurriculumOrderIndex(id) {
    return this.axiosIns.get(`${this.jwtConfig.curriculumEndpoint}/pages-order-index/${id}`)
  }

  cloneCurriculumPages(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.curriculumPagesEndpoint}/${id}/clone`, ...args)
  }

  createCurriculumPage(...args) {
    return this.axiosIns.post(this.jwtConfig.curriculumPagesEndpoint, ...args)
  }

  updateCurriculumPage(id, ...args) {
    return this.axiosIns.put(
      `${this.jwtConfig.curriculumPagesEndpoint}/${id}`,
      ...args,
    )
  }

  deleteCurriculumPage(id) {
    return this.axiosIns.delete(`${this.jwtConfig.curriculumPagesEndpoint}/${id}`)
  }

  getCurriculumPage(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.curriculumPagesEndpoint}/${id}`, ...args)
  }

  submitStudentActivities(...args) {
    return this.axiosIns.post(`${this.jwtConfig.studentActivitiesEndpoint}`, ...args)
  }

  recordFeedbackTime(...args) {
    return this.axiosIns.put(`${this.jwtConfig.feedbackTimeEndpoint}`, ...args)
  }

  getStudentActivities(id, ...args) {
    return this.axiosIns.get(id ? `${this.jwtConfig.getStudentActivitiesEndpoint}?id=${id}` : `${this.jwtConfig.getStudentActivitiesEndpoint}`, ...args)
  }

  getClassRubrics(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.classRubricEndpoint}?type=class&class_id=${id}`, ...args)
  }

  getTeacherRubrics(...args) {
    return this.axiosIns.get(`${this.jwtConfig.classRubricEndpoint}`, ...args)
  }

  deleteTeacherRubric(id) {
    return this.axiosIns.delete(`${this.jwtConfig.classRubricEndpoint}/${id}`)
  }

  createClassRubric(...args) {
    return this.axiosIns.post(`${this.jwtConfig.classRubricEndpoint}`, ...args)
  }

  updateClassRubric(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.classRubricEndpoint}/${id}`, ...args)
  }

  deleteClassRubric(id) {
    return this.axiosIns.delete(`${this.jwtConfig.classRubricEndpoint}/${id}`)
  }

  syncClassRubrics(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.classEndpoint}/${id}/rubric/sync`, ...args)
  }

  getStudentRubrics(id, classId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.studentRubricEndpoint}?user_id=${id}&class_id=${classId}`, ...args)
  }

  createStudentRubric(...args) {
    return this.axiosIns.post(`${this.jwtConfig.studentRubricEndpoint}`, ...args)
  }

  updateStudentRubric(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.studentRubricEndpoint}/${id}`, ...args)
  }

  deleteStudentRubric(id, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.studentRubricEndpoint}/${id}`, ...args)
  }

  calculateStudentAttention(...args) {
    return this.axiosIns.post((process.env.NODE_ENV || 'development') === 'development' ? 'https://beta.globalenglishcampus.com/face-score/api' : this.jwtConfig.studentAttentionApi, ...args)
  }

  getProblemForPreview(id, level) {
    return this.axiosIns.get(`${this.jwtConfig.problemEndpoint}/${id}/preview?level=${level}`)
  }

  createGame(...args) {
    return this.axiosIns.post(`${this.jwtConfig.gameEndpoint}`, ...args)
  }

  getGameByLesson(id) {
    return this.axiosIns.get(`${this.jwtConfig.gameEndpoint}?type=lesson&lesson_id=${id}`)
  }

  updateGame(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.gameEndpoint}/${id}`, ...args)
  }

  getLessonTypes() {
    return this.axiosIns.get(`${this.jwtConfig.lessonTypeEndpoint}?type=LessonType`)
  }

  copyProblem(...args) {
    return this.axiosIns.post(`${this.jwtConfig.copyProblemEndpoint}`, ...args)
  }

  getAnswerPreview(id) {
    return this.axiosIns.get(`${this.jwtConfig.answerPreviewEndpoint}/${id}/user-input`)
  }

  getNotes() {
    return this.axiosIns.get(this.jwtConfig.notesEndpoint)
  }

  createNote(...args) {
    return this.axiosIns.post(this.jwtConfig.notesEndpoint, ...args)
  }

  updateNote(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.notesEndpoint}/${id}`, ...args)
  }

  deleteNote(id) {
    return this.axiosIns.delete(`${this.jwtConfig.notesEndpoint}/${id}`)
  }

  getNotesForProblem(id) {
    return this.axiosIns.get(`${this.jwtConfig.notesByProblem}/${id}`)
  }

  getNotesForLesson(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.notesByLesson}/${id}`, ...args)
  }

  getHint(...args) {
    return this.axiosIns.get(`${this.jwtConfig.hintsEndpoint}`, ...args)
  }

  generateProblemsForCurriculum(...args) {
    return this.axiosIns.post(this.jwtConfig.generateProblemsEndpoint, ...args)
  }

  updatePageMeta(...args) {
    return this.axiosIns.put(this.jwtConfig.pageMetaEndpoint, ...args)
  }

  deletePageMeta(id) {
    const args = {
      page_meta_id: id,
      _method: 'delete',
    }
    return this.axiosIns.post(this.jwtConfig.pageMetaEndpoint, args)
  }

  updateVocab(...args) {
    return this.axiosIns.put(this.jwtConfig.vocabEndpoint, ...args)
  }

  deleteVocab(id) {
    return this.axiosIns.delete(`${this.jwtConfig.vocabEndpoint}?id=${id}`)
  }

  updateCurriculumPages(...args) {
    return this.axiosIns.post(this.jwtConfig.updateCurriculumPagesApi, ...args)
  }

  deleteCurriculumPageSet(args) {
    const data = { _method: 'delete', ...args }
    return this.axiosIns.post(this.jwtConfig.curriculumPageSetEndpoint, data)
  }

  getStudentClassroomEssays(classRoom, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classRoom}/get-student-essay`, ...args)
  }

  getEssay(...args) {
    return this.axiosIns.get(this.jwtConfig.essayEndpoint, ...args)
  }

  createEssay(...args) {
    return this.axiosIns.post(this.jwtConfig.essayEndpoint, ...args)
  }

  updateEssay(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.essayEndpoint}/${id}`, ...args)
  }

  getCurriculumImages(id) {
    return this.axiosIns.get(`${this.jwtConfig.curriculumEndpoint}/images/${id}`)
  }

  scoreEssay(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.essayEndpoint}/${id}/score`, ...args)
  }

  getEssayComments(id) {
    return this.axiosIns.get(`${this.jwtConfig.essayEndpoint}/${id}/comment`)
  }

  commentOnEssay(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.essayEndpoint}/${id}/comment`, ...args)
  }

  updateEssayComment(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.essayEndpoint}/comment/${id}`, ...args)
  }

  deleteEssayComment(id) {
    return this.axiosIns.delete(`${this.jwtConfig.essayEndpoint}/comment/${id}`)
  }

  requestEssayEditAccess(id) {
    return this.axiosIns.post(`${this.jwtConfig.essayEndpoint}/submitEditRequest?lesson_id=${id}`)
  }

  acceptEssayEditRequestPermission(...args) {
    return this.axiosIns.post(`${this.jwtConfig.essayEndpoint}/approveEditRequest`, ...args)
  }

  publicSearchStudents(...args) {
    return this.axiosIns.post(`${this.jwtConfig.usersEndpoint}/search`, ...args)
  }

  getStudentDashboardData(...args) {
    return this.axiosIns.get(this.jwtConfig.studentDashboardEndpoint, ...args)
  }

  getStudentRewardCount(...args) {
    return this.axiosIns.get(`${this.jwtConfig.studentEndpoint}/rewards/count`, ...args)
  }

  getStudentTableData(type) {
    return this.axiosIns.get(`${this.jwtConfig.studentEndpoint}/${type}`)
  }

  // HotSpot
  getHotspot() {
    return this.axiosIns.get(`${this.jwtConfig.hotspotEndpoint}`)
  }

  saveHotspot(...args) {
    return this.axiosIns.post(`${this.jwtConfig.hotspotEndpoint}`, ...args)
  }

  savePoints(...args) {
    return this.axiosIns.post(`${this.jwtConfig.hintsEndpoint}`, ...args)
  }

  updateHotspot(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.hotspotEndpoint}/${id}`, ...args)
  }

  deleteHotspot() {
    return this.axiosIns.delete(`${this.jwtConfig.hotspotEndpoint}`)
  }

  deletePoint(id) {
    return this.axiosIns.delete(`${this.jwtConfig.hotspotEndpoint}/point/${id}`)
  }

  updateHotspotPoint(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.hotspotPointEndpoint}/${id}`, ...args)
  }

  filterClassStudents(...args) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/student/filter`, ...args)
  }

  sendNotifications(...args) {
    return this.axiosIns.post(`${this.jwtConfig.sendNotificationsEndpoint}`, ...args)
  }

  updateUserSettings(...args) {
    return this.axiosIns.post(`${this.jwtConfig.updateUserSettingsEndpoint}`, ...args)
  }

  // permissions api's
  createPermission(type, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.permissionsEndpoint}/user/${type}`, ...args)
  }

  getPermission(type, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.permissionsEndpoint}/grants/${type}/list`, ...args)
  }

  getAllPermissions(...args) {
    return this.axiosIns.get(`${this.jwtConfig.permissionsEndpoint}/all`, ...args)
  }

  deletePermission(id) {
    return this.axiosIns.delete(`${this.jwtConfig.permissionsEndpoint}/grants/${id}`)
  }

  getCourseForClass(classId) {
    return this.axiosIns.get(`${this.jwtConfig.courseForClassEndpoint}?room_id=${classId}`)
  }

  getReportsForCourseAndClass(classId, courseId) {
    return this.axiosIns.get(`${this.jwtConfig.classCourseEndpoint}/${classId}/course/${courseId}/reporting`)
  }

  getLessonsPermissions(...args) {
    return this.axiosIns.get(`${this.jwtConfig.permissionsEndpoint}/grants/lesson.show`, ...args)
  }

  getPointsSetting(...args) {
    return this.axiosIns.get(this.jwtConfig.rewardEndpoint, ...args)
  }

  storePointsSetting(...args) {
    return this.axiosIns.post(this.jwtConfig.rewardEndpoint, ...args)
  }

  updatePointsSetting(settingId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.rewardEndpoint}/${settingId}`, ...args)
  }

  getPointsSettingHistory(...args) {
    return this.axiosIns.get(`${this.jwtConfig.pointsSettingEndpoint}/history`, ...args)
  }

  recordUserTimeSpent(...args) {
    return this.axiosIns.post(`${this.jwtConfig.userEndpoint}/record-active-time`, ...args)
  }

  uploadUserExcel(...args) {
    return this.axiosIns.post(`${this.jwtConfig.userEndpoint}/mass-upload`, ...args)
  }

  getCoursesPermissions(...args) {
    return this.axiosIns.get(`${this.jwtConfig.permissionsEndpoint}/grants/lessonGroups.show`, ...args)
  }

  getStudentCharacter() {
    return this.axiosIns.get(this.jwtConfig.studentCharacterEndpoint)
  }

  updateStudentCharacter(...args) {
    return this.axiosIns.patch(this.jwtConfig.studentCharacterUpdateEndpoint, ...args)
  }

  getStudentTime(...args) {
    return this.axiosIns.get(`${this.jwtConfig.userEndpoint}/student-active-time`, ...args)
  }

  updatePageLink(...args) {
    return this.axiosIns.post(`${this.jwtConfig.statementEndpoint}/page-link/update`, ...args)
  }

  updateIncorrectAnswers(...args) {
    return this.axiosIns.post(`${this.jwtConfig.problemEndpoint}/incorrect-answer`, ...args)
  }

  getTopLessonAchiever(id) {
    return this.axiosIns.get(`${this.jwtConfig.classCourseEndpoint}/${id}/report`)
  }

  getScoreData(id) {
    return this.axiosIns.get(`${this.jwtConfig.classCourseEndpoint}/${id}/report`)
  }

  retoreClassroom(id) {
    return this.axiosIns.patch(`${this.jwtConfig.classEndpoint}/restore/${id}`)
  }

  updateProblemDDFSet(...args) {
    return this.axiosIns.patch(`${this.jwtConfig.problemEndpoint}/ddf-set/update`, ...args)
  }

  sendCancelCallEvent(...args) {
    return this.axiosIns.post('/api/v1/cancel-busy-call', ...args)
  }

  checkProblemSolutionForDemoUser(id, args, config) {
    return this.axiosIns.post(
      `${this.jwtConfig.demoStudentLabProblemEndPoint}/${id}/check`,
      args,
      config,
    )
  }

  getDemoLessonReport(...args) {
    return this.axiosIns.get(`${this.jwtConfig.getDemoEndpoint}/lesson/report`, ...args)
  }

  getTeacherSchedules(...args) {
    return this.axiosIns.get(`${this.jwtConfig.teacherSchedularEndpoint}`, ...args)
  }

  postNewSchedule(args) {
    return this.axiosIns.post(
      `${this.jwtConfig.teacherSchedularEndpoint}`,
      args,
    )
  }

  getScheduleDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.teacherSchedularEndpoint}/${id}`)
  }

  getScheduleReserveTime(id) {
    return this.axiosIns.get(`${this.jwtConfig.teacherSchedularEndpoint}/reserved-times/${id}`)
  }

  storeScheduleReserveTime(id, args) {
    return this.axiosIns.post(`${this.jwtConfig.teacherSchedularEndpoint}/reserved-times/${id}`, args)
  }

  updateNewSchedule(id, args) {
    return this.axiosIns.patch(
      `${this.jwtConfig.teacherSchedularEndpoint}/${id}`,
      args,
    )
  }

  destroySchedule(id, ...args) {
    return this.axiosIns.delete(
      `${this.jwtConfig.teacherSchedularEndpoint}/${id}`,
      ...args,
    )
  }

  getArchivedSchedule(...args) {
    return this.axiosIns.get(
      `${this.jwtConfig.teacherSchedularEndpoint}/get-archived-schedule`,
      ...args,
    )
  }

  restoreArchivedSchedule(...args) {
    return this.axiosIns.patch(
      `${this.jwtConfig.teacherSchedularEndpoint}/restore-archived-schedule`,
      ...args,
    )
  }

  getParentUserByType(type) {
    return this.axiosIns.get(`${this.jwtConfig.userEndpoint}/${type}`)
  }

  getClassRoom(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}`)
  }

  getClassRoomCourseCount(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/courses`)
  }

  getStudentAttendance(...args) {
    return this.axiosIns.get(`${this.jwtConfig.studentEndpoint}/attendance`, ...args)
  }

  getAllStudentAttendance(...args) {
    return this.axiosIns.get(`${this.jwtConfig.studentEndpoint}/attendance/all`, ...args)
  }

  getStudentRewardEarning(...args) {
    return this.axiosIns.get(`${this.jwtConfig.earningsEndpoint}`, ...args)
  }

  getGlobalSetting(...args) {
    return this.axiosIns.get(this.jwtConfig.globalSettingEndpoint, ...args)
  }

  updateGlobalSettings(...args) {
    return this.axiosIns.patch(`${this.jwtConfig.globalSettingEndpoint}/update`, ...args)
  }

  updateGlobalSettingsFile(args, config) {
    return this.axiosIns.post(`${this.jwtConfig.globalSettingEndpoint}/update-file`, args, config)
  }

  updatePersonalityPopupSetting(...args) {
    return this.axiosIns.patch(`${this.jwtConfig.globalSettingEndpoint}/personality-popup/update`, ...args)
  }

  setOffLessons(toggle, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.lessonsEndpoint}/engine/${toggle}`, ...args)
  }

  getSelectedLessons(...args) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/selectedLessons`, ...args)
  }

  getSelectedLessonsForTest(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/class-tests`)
  }

  getRulesSetting(...args) {
    return this.axiosIns.get(`${this.jwtConfig.siteMetaEndpoint}/reward_rules`, ...args)
  }

  storeRulesSetting(...args) {
    return this.axiosIns.post(this.jwtConfig.siteMetaEndpoint, ...args)
  }

  updateRulesSetting(...args) {
    return this.axiosIns.post(this.jwtConfig.siteMetaEndpoint, ...args)
  }

  destroyGameWrapperForProblem(id) {
    return this.axiosIns.delete(`${this.jwtConfig.gameEndpoint}/remove-wrapper/${id}`)
  }

  getParingInfoByClass(classId) {
    return this.axiosIns.get(`${this.jwtConfig.paringEndpoint}/index/${classId}`)
  }

  storeParingInfoByClass(...args) {
    return this.axiosIns.post(`${this.jwtConfig.paringEndpoint}/store`, ...args)
  }

  getParingNoteByLesson({ lessonId, classId }) {
    return this.axiosIns.get(`${this.jwtConfig.paringEndpoint}/notes/index/${classId}/${lessonId}`)
  }

  getParingNoteByProblems({ studentId, problemId }) {
    if (studentId) {
      return this.axiosIns.get(`${this.jwtConfig.paringEndpoint}/notes/get-notes/${problemId}/${studentId}`)
    }
    return this.axiosIns.get(`${this.jwtConfig.paringEndpoint}/notes/get-notes/${problemId}`)
  }

  getClassRoomStat(classId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/students/stats`, ...args)
  }

  getClassStudentReport(classId, studentId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/students/${studentId}/reports`, ...args)
  }

  getAllPairNotes(...args) {
    return this.axiosIns.get(`${this.jwtConfig.paringEndpoint}/notes/get-all-pair-notes`, ...args)
  }

  getStudentById(id) {
    return this.axiosIns.get(`${this.jwtConfig.usersEndpoint}/${id}`)
  }

  getClassroomSkillChart(classId, studentId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/user/${studentId}/chart`)
  }

  getClassroomSkillChartForSocial(studentId) {
    return this.axiosIns.get(`${this.jwtConfig.userEndpoint}/${studentId}/social-skills`)
  }

  getClassroomSkillChartForStudy(classId, studentId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/user/${studentId}/study-skills`)
  }

  getClassroomSkillChartForCourses(classId, courseId, studentId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/course/${courseId}/user/${studentId}/chart`, ...args)
  }

  requestLockedLessonToUnlock(classId, lessonId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/unlock-lesson/${lessonId}`)
  }

  postNotesComment(...args) {
    return this.axiosIns.post(`${this.jwtConfig.notesCommentEndPoint}`, ...args)
  }

  getNotesComment(...args) {
    return this.axiosIns.get(`${this.jwtConfig.notesCommentEndPoint}`, ...args)
  }

  deleteNoteComment(id) {
    return this.axiosIns.delete(`${this.jwtConfig.notesCommentEndPoint}/${id}`)
  }

  updateStudentRole(classId, role, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.classEndpoint}/${classId}/assign-role/${role}`, ...args)
  }

  updateStudentLiveLink(classId, studentId, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.classEndpoint}/${classId}/update-link/${studentId}`, ...args)
  }

  updateStudentRoleScore(classId, type, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.classEndpoint}/${classId}/update-score/${type}`, ...args)
  }

  updateStudentAttendanceWithLiveLink(...args) {
    return this.axiosIns.post(`${this.jwtConfig.studentAttendanceEndpoint}`, ...args)
  }

  getStudentAttendanceWithLiveLink(...args) {
    return this.axiosIns.get(`${this.jwtConfig.studentAttendanceEndpoint}`, ...args)
  }

  bulkVerifyStudentAttendanceWithLiveLink(...args) {
    return this.axiosIns.patch(`${this.jwtConfig.studentAttendanceEndpoint}/bulk-verify`, ...args)
  }

  updateStudentAttendanceByTeacher(...args) {
    return this.axiosIns.post(`${this.jwtConfig.studentAttendanceEndpoint}/update-attendance`, ...args)
  }

  recordGameTime(loaderId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.getDomainByLesson}/${loaderId}/record-game-time`, ...args)
  }

  getMyPairs(classId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.paringEndpoint}/my-pair/${classId}`, ...args)
  }

  filterDomainStandard(...args) {
    return this.axiosIns.get(`${this.jwtConfig.domain}/filter`, ...args)
  }

  moveDomainStandard(domainId, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.domain}/move/${domainId}`, ...args)
  }

  getUserTimeTracked(userId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.userEndpoint}/get-record-time/${userId}`, ...args)
  }

  getGameLists(...args) {
    return this.axiosIns.get(`${this.jwtConfig.gameEndPoint}/game-lists`, ...args)
  }

  getGameSettings() {
    return this.axiosIns.get(`${this.jwtConfig.gameEndPoint}/game-settings`)
  }

  updateGameSettingsValue(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.gameEndPoint}/game-settings/${id}`, ...args)
  }

  toggleGameAvailability(gameId, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.gameEndPoint}/toggle-game/${gameId}`, ...args)
  }

  getClassroomGameSettings(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.gameEndPoint}/classroom/${id}`, ...args)
  }

  setClassroomGameSettings(...args) {
    return this.axiosIns.post(`${this.jwtConfig.gameEndPoint}/classroom`, ...args)
  }
  // gec partners

  getGecPartners(...args) {
    return this.axiosIns.get(`${this.jwtConfig.gecPartnerEndPoint}`, ...args)
  }

  storeGecPartners(args) {
    return this.axiosIns.post(`${this.jwtConfig.gecPartnerEndPoint}`, args, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  showGecPartner(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.gecPartnerEndPoint}/${id}`, ...args)
  }

  updateGecPartner(id, args) {
    return this.axiosIns.post(`${this.jwtConfig.gecPartnerEndPoint}/${id}`, args, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  deleteGecPartner(id, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.gecPartnerEndPoint}/${id}`, ...args)
  }

  getCompanyLogo(token) {
    return this.axiosIns.get(`/api/v1/get-company-logo/${token}/logo`)
  }

  getGroupReport(classId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/readability`, ...args)
  }

  getScoreReport(classId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/score`, ...args)
  }

  getLessonScoreReport(classId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/score/lesson`, ...args)
  }

  getGroupAttendanceReport(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/attendance`)
  }

  getGroupActivityReport(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/activity`)
  }

  getGroupAcademicReport(classId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/academic`, ...args)
  }

  getRealTimeMonitor(classId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.realTimeEndPoint}/monitor/${classId}`, ...args)
  }

  getTestStatBasedOnClassRoom(classId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.realTimeEndPoint}/get-test-stat/${classId}`, ...args)
  }

  getPersonalities() {
    return this.axiosIns.get(this.jwtConfig.personalityEndpoint)
  }

  checkPopupSetting(...args) {
    return this.axiosIns.get(`${this.jwtConfig.personalityEndpoint}/popup-check`, ...args)
  }

  getPersonalitiesByGroup() {
    return this.axiosIns.get(`${this.jwtConfig.personalityEndpoint}/group`)
  }

  storePersonality(...args) {
    return this.axiosIns.post(`${this.jwtConfig.personalityEndpoint}`, ...args)
  }

  updatePersonality(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.personalityEndpoint}/${id}`, ...args)
  }

  deletePersonality(id) {
    return this.axiosIns.delete(`${this.jwtConfig.personalityEndpoint}/${id}`)
  }

  checkPersonality() {
    return this.axiosIns.get(`${this.jwtConfig.studentPersonalityEndpoint}/check`)
  }

  getPersonality(id) {
    return this.axiosIns.get(`${this.jwtConfig.studentPersonalityEndpoint}/${id}`)
  }

  getPersonalityByEvaluatedBy(id, evaluatedBy, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.studentPersonalityEndpoint}/${id}/${evaluatedBy}`, ...args)
  }

  getStudentPersonalities(id) {
    return this.axiosIns.get(`${this.jwtConfig.studentPersonalityEndpoint}/${id}/all`)
  }

  storeStudentPersonality(...args) {
    return this.axiosIns.post(this.jwtConfig.studentPersonalityEndpoint, ...args)
  }

  updateStudentPersonality(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.studentPersonalityEndpoint}/${id}}`, ...args)
  }

  getLessonNotesByPage(lessonId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.notesByLesson}/${lessonId}/by-page`, ...args)
  }

  storeSharedLessons(...args) {
    return this.axiosIns.post(this.jwtConfig.shareEndPoint, ...args)
  }

  removeSharedLesson(id, ...args) {
    return this.axiosIns.delete(`${this.jwtConfig.shareEndPoint}/${id}`, ...args)
  }

  getClassTest(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/class-tests`)
  }

  getClassTestLesson(testID) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/class-tests/${testID}/lessons`)
  }

  getWordsForParagraph({ pageId, setNo }) {
    return this.axiosIns.get(`${this.jwtConfig.wordsForParagraphEndPoint}?page_id=${pageId}&set_no=${setNo}`)
  }

  updateIncorrectWordsForParagraph(wordId, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.wordsForParagraphEndPoint}/${wordId}/update-incorrect`, ...args)
  }

  saveWordsForParagraph(pageId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.wordsForParagraphEndPoint}/${pageId}`, ...args)
  }

  imageUploadForParagraphWords(wordId, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.wordsForParagraphEndPoint}/${wordId}/image-upload`, ...args)
  }

  deleteWordsForParagraph(wordId) {
    return this.axiosIns.delete(`${this.jwtConfig.wordsForParagraphEndPoint}/${wordId}`)
  }

  getClassTestList(classId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/class-tests-lists`)
  }

  getClassTestReport(classId, testId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/${testId}/class-tests-detail`)
  }

  updateDefaultClassroom(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.classEndpoint}/default/${id}`, ...args)
  }

  requestTeacher(...args) {
    return this.axiosIns.post(`${this.jwtConfig.classEndpoint}/request-teacher`, ...args)
  }

  guestLogin(args) {
    return this.axiosIns.post(`${this.jwtConfig.guestLoginEndpoint}`, args)
  }

  getCoursesWithLinearLesson() {
    return this.axiosIns.get(`${this.jwtConfig.sdpLessonEndPoint}/courses-with-linear-lesson`)
  }

  updateSDPLesson(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.sdpLessonEndPoint}/${id}/build`, ...args)
  }

  getSDPLessonProblemForEdit(id) {
    return this.axiosIns.get(`${this.jwtConfig.sdpLessonEndPoint}/${id}/problems`)
  }

  getLinearLessonProblemByLangLevel(...args) {
    return this.axiosIns.get(`${this.jwtConfig.sdpLessonEndPoint}/problem-by-level`, ...args)
  }

  getGradeLists(classId = 0) {
    if (classId === 0) {
      return this.axiosIns.get(this.jwtConfig.gradeLists)
    }
    return this.axiosIns.get(`${this.jwtConfig.gradeLists}?classId=${classId}`)
  }

  destroyCurriculumPage(pageId) {
    return this.axiosIns.delete(`${this.jwtConfig.apiEndPoint}/page/${pageId}`)
  }

  restoreDummyDatabase() {
    return this.axiosIns.post(`${this.jwtConfig.globalSettingEndpoint}/update-dummy-db`)
  }

  sortProblemByName(lessonId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.problemEndpoint}/${lessonId}/re-order-problem`, ...args)
  }

  getIssues(page, id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.issuesEndPoint}/${id}?page=${page}`, ...args)
  }

  getAllIssues(page, v, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.issuesEndPoint}?page=${page}`, ...args)
  }

  storeIssues(args) {
    return this.axiosIns.post(this.jwtConfig.issuesEndPoint, ...args)
  }

  updateIssue(id, args) {
    return this.axiosIns.patch(`${this.jwtConfig.issuesEndPoint}/${id}/update`, ...args)
  }

  getUnseenIssue() {
    return this.axiosIns.get(`${this.jwtConfig.issuesEndPoint}/check-unseen-issue`)
  }

  updateIssueSeen(id) {
    return this.axiosIns.patch(`${this.jwtConfig.issuesEndPoint}/${id}/update-unseen-issue`)
  }

  getLessonDetailInfo(id) {
    return this.axiosIns.get(`${this.jwtConfig.lessonsEndpoint}/detail/${id}`)
  }

  getUsersRelations(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.userEndpoint}/relations/${id}/parents`, ...args)
  }

  searchParentUsers(userId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.userEndpoint}/search/${userId}/parents`, ...args)
  }

  updateRelation(...args) {
    return this.axiosIns.post(`${this.jwtConfig.userEndpoint}/relation/update`, ...args)
  }

  showFaultInCB(lessonId) {
    return this.axiosIns.get(`${this.jwtConfig.lessonsEndpoint}/${lessonId}/detect-problem`)
  }

  updateLessonLevelCalibration(lessonId, $args) {
    return this.axiosIns.put(`${this.jwtConfig.apiEndPoint}/calibration/${lessonId}`, $args)
  }

  getLessonLevelCalibration(lessonId) {
    return this.axiosIns.get(`${this.jwtConfig.apiEndPoint}/calibration/${lessonId}`)
  }

  updateLessonRule(...args) {
    return this.axiosIns.put(`${this.jwtConfig.classEndpoint}/lesson-rules`, ...args)
  }

  addHelpVideo(...args) {
    return this.axiosIns.post(`${this.jwtConfig.videosEndpoint}`, ...args)
  }

  updateHelpVideo(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.videosEndpoint}/${id}`, ...args)
  }

  deleteHelpVideo(id) {
    return this.axiosIns.delete(`${this.jwtConfig.videosEndpoint}/${id}`)
  }

  getAllHelpVideos(...args) {
    return this.axiosIns.get(`${this.jwtConfig.videosEndpoint}`, ...args)
  }

  getAllRelatedHelpVideos(...args) {
    return this.axiosIns.get(`${this.jwtConfig.videosEndpoint}/related`, ...args)
  }

  storeUserMeta(metaType, value) {
    return this.axiosIns.post(`${this.jwtConfig.videosEndpoint}/meta/${metaType}`, { value })
  }

  sendEmailToStudent(classroomId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.apiEndPoint}/classroom/${classroomId}/email/student`, ...args)
  }

  getAllThirdParties(...args) {
    return this.axiosIns.get(`${this.jwtConfig.thirdPartyEndpoint}`, ...args)
  }

  getAllUsersByProvider(provider, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.thirdPartyEndpoint}/${provider}`, ...args)
  }

  // issue comments
  getIssueComments(id, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.issuesEndPoint}/${id}/comments`, ...args)
  }

  addIssueComment(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.issuesEndPoint}/${id}/comments`, ...args)
  }

  deleteIssueComment(id, commentId) {
    return this.axiosIns.delete(`${this.jwtConfig.issuesEndPoint}/${id}/comments/${commentId}`)
  }

  editIssueComment(id, commentId, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.issuesEndPoint}/${id}/comments/${commentId}`, ...args)
  }

  getClassRoomLesson(classId, courseId, lessonId) {
    return this.axiosIns.get(`${this.jwtConfig.classEndpoint}/${classId}/get-classroom-lesson?courseId=${courseId}&lessonId=${lessonId}`)
  }

  updateUserBannerImage(...args) {
    return this.axiosIns.post(`${this.jwtConfig.userEndpoint}/banner/update`, ...args)
  }

  getUserBannerImage(userId) {
    return this.axiosIns.get(`${this.jwtConfig.userEndpoint}/${userId}/get-banner`)
  }

  getProblemsLessonId(lessonId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.problemEndpoint}?lid=${lessonId}`, ...args)
  }

  getProblemFromFollowUpEvent(event, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.followUpEndpoint}/show/${event}`, ...args)
  }

  storeFollowUp(classId, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.followUpEndpoint}`, ...args)
  }

  updateFollowUp(eventId, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.followUpEndpoint}/${eventId}/update`, ...args)
  }

  getFollowUps(classId, studentId) {
    return this.axiosIns.get(`${this.jwtConfig.classCourseEndpoint}/${classId}/student/${studentId}/follow-up`)
  }

  getProblemsByVocabs(vocab) {
    return this.axiosIns.get(`${this.jwtConfig.followUpEndpoint}/get-problem?word=${vocab}`)
  }

  getCalibrationPermission() {
    return this.axiosIns.get(`${this.jwtConfig.permissionsEndpoint}/grants/settings.calibration`)
  }

  followUpTeacherCreateProblem(...args) {
    return this.axiosIns.post(`${this.jwtConfig.followUpEndpoint}/teacher-problem`, ...args)
  }

  // follow up notes
  getTeacherFollowUpNotes(...args) {
    return this.axiosIns.get(`${this.jwtConfig.followUpEndpoint}/note`, ...args)
  }

  storeTeacherFollowUpNote(...args) {
    return this.axiosIns.post(`${this.jwtConfig.followUpEndpoint}/note/store`, ...args)
  }

  updateTeacherFollowUpNote(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.followUpEndpoint}/note/update/${id}`, ...args)
  }

  getTeacherCreateProblem(lessonId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.followUpEndpoint}/get-teacher-problem/${lessonId}`, ...args)
  }

  getFailedProblemOfStudent(studentId, ...args) {
    return this.axiosIns.get(`${this.jwtConfig.followUpEndpoint}/get-student-problem/${studentId}`, ...args)
  }

  generateMachineGeneratedProblem(...args) {
    return this.axiosIns.post(`${this.jwtConfig.followUpEndpoint}/generate-mgp-problem`, ...args)
  }

  updateStudentStudyTime(...args) {
    return this.axiosIns.post(`${this.jwtConfig.studentAttendanceEndpoint}/update-study-time`, ...args)
  }

  updateAllStudyTime(...args) {
    return this.axiosIns.post(`${this.jwtConfig.studentAttendanceEndpoint}/update-all-study-time`, ...args)
  }

  updateCourseTags(id, ...args) {
    return this.axiosIns.post(
      `${this.jwtConfig.coursesEndpoint}/${id}/store-tags`,
      ...args,
    )
  }

  getCoursesTags(id) {
    return this.axiosIns.get(`${this.jwtConfig.coursesEndpoint}/${id}/tags`)
  }

  updateLessonTags(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.lessonsEndpoint}/${id}/store-tags`, ...args)
  }

  getLessonsTags(id) {
    return this.axiosIns.get(`${this.jwtConfig.lessonsEndpoint}/${id}/tags`)
  }

  getLessonMapper(...args) {
    return this.axiosIns.get(`${this.jwtConfig.lessonMapperEndpoint}`, ...args)
  }

  storeLessonMapper(args) {
    return this.axiosIns.post(`${this.jwtConfig.lessonMapperEndpoint}`, args)
  }

  deleteLessonMapper(id) {
    return this.axiosIns.delete(`${this.jwtConfig.lessonMapperEndpoint}/${id}`)
  }

  updateLessonMapper(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.lessonMapperEndpoint}/${id}`, ...args)
  }

  getLessonWiseScorm(id) {
    return this.axiosIns.get(`${this.jwtConfig.lessonsScormEndpoint}/${id}`)
  }

  deactivateUser(id, ...args) {
    return this.axiosIns.post(`${this.jwtConfig.userEndpoint}/${id}/deactivate`, ...args)
  }

  // get detail report lesson complete
  getLessonCompleteReport(...args) {
    return this.axiosIns.get(`${this.jwtConfig.detailReportEndpoint}/lesson-complete`, ...args)
  }

  // get detail report monthly login
  getMonthlyLoginReport() {
    return this.axiosIns.get(`${this.jwtConfig.detailReportEndpoint}/monthly-login`)
  }

  // get detail report monthly login
  getCombinedReport(...args) {
    return this.axiosIns.get(`${this.jwtConfig.detailReportEndpoint}/combined-report`, ...args)
  }
}
