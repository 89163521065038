<template>
  <b-modal :visible="true"
           title="Edit Meta"
           size="lg"
           @close="$emit('close')"
           @hide="$emit('close')"
  >

    <editor v-if="isHtml"
            v-model="editableContent"
    />
    <BFormTextarea v-else
                   v-model="editableContent"
    />
    <template #modal-footer>
      <div>
        <b-button variant="secondary"
                  @click="$emit('close')"
        >
          Cancel
        </b-button>
        <b-button class="ml-1"
                  variant="success"
                  :disabled="state.isProcessing"
                  @click="handleUpdate"
        >
          <b-spinner v-if="state.isProcessing"
                     small
          /> Submit
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script setup>
import {
  BModal, BButton, BSpinner, BFormTextarea,
} from 'bootstrap-vue'
import { ref, reactive, getCurrentInstance } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import EventBus from '@/utils/eventBus'
import Editor from '@views/common/components/QuillEditorCustom.vue'

const root = getCurrentInstance().proxy.$root

const props = defineProps({
  metaId: {
    type: Number,
    required: true,
  },
  content: {
    type: String,
    required: true,
  },
})

const editableContent = ref(props.content)

const state = reactive({
  isProcessing: false,
})

const emits = defineEmits(['close'])
const elementToCheck = 'p'
const regex = new RegExp(`<${elementToCheck}(\\s|>)`, 'i')
const isHtml = regex.test(props.content)

const handleUpdate = () => {
  state.isProcessing = true
  useJwt.updateEbookBookContent(props.metaId, {
    content: editableContent.value,
  }).then(response => {
    EventBus.$emit('refetchPageData')
    emits('close')
    root.showSuccessMessage(response)
  }).catch(error => {
    root.showErrorMessage(error)
  })
    .finally(() => {
      state.isProcessing = false
    })
}
</script>
